import React from "react";
import ReactGA from 'react-ga';

class SocialSharing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    handleClickEvent = () => {
        this.setState({active: !this.state.active});
    }

    facebookShare = () => {
        window.open(`https://www.facebook.com/sharer.php?u=${encodeURI(window.location)}`, '', 'height-300,width=300');
        ReactGA.event({
            category: 'Social Media Share',
            action: `Facebook - ${this.props.stationId}`
        });
    } 
    twitterShare = () => {
        window.open(`https://twitter.com/share?url=${encodeURI(window.location)}`, '', 'height-300,width=300');
        ReactGA.event({
            category: 'Social Media Share',
            action: `Twitter - ${this.props.stationId}`
        });
    } 

    render() {
        return(
            <div className={`share ${this.state.active ? ' active' : ''}`}>
                <i className='icon-share' onClick={this.handleClickEvent} />
                <div className="wrapper">
                    <i className="icon-facebook" onClick={this.facebookShare}/>
                    <i className="icon-twitter" onClick={this.twitterShare}/>
                </div>
            </div>
        )
    }
}

export default SocialSharing;