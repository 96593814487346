import React from 'react';

const PageTitle = () => {
  return (
    <div className="page-title">
        <h1>Music <span>picked</span> by Humans</h1>
    </div>
  );
}

export default PageTitle;
