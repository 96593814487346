import React from "react";
import error from "../assets/images/error.png";

const StationError = props => {

  let coverImage, textColor, backgorunColor;

  coverImage = {
    backgroundImage: "url(" + error + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  };
  textColor = {
    color: props.theme,
  };
  backgorunColor = {
    background: props.theme,
  };

  const gradientOverlay = {
    background:
      "linear-gradient(-30deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, .8) 80%)"
  };

  return (
    <div
      className={`radio-station ${props.id} ${props.play ? "is-playing" : ""}`}
    >
      <h3 >
        <i className="icon-addict-radio" style={backgorunColor}/>
        {props.name}
      </h3>

      <div className="cover" style={coverImage}>
        <div className="gradient-overlay" style={gradientOverlay} />
        <div className="no-info-background">
          <h3 dangerouslySetInnerHTML={{ __html: props.title }} style={textColor}/>
          <span dangerouslySetInnerHTML={{ __html: props.artist }} style={textColor}/>
        </div>
      </div>
      <div className="player">
        <i className='control icon-reload' onClick={() => {window.location.reload()}}/>
      </div>
    </div>
  );
};

export default StationError;
