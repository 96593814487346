import React from "react";

const Footer = () => {
  return (
    <div className="footer">
        {/* <div style={{"float: right; padding: 0 30px 15px 0;"}}>
            <a href="/privacy-policy" className="link"> PP </a>
        </div> */}
          
    </div>
  );
};

export default Footer;
