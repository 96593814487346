import React from "react";
import Header from "./components/Header";
import ReactGA from 'react-ga';

const HowToListen = () => {

    ReactGA.initialize('UA-3951789-2');
    ReactGA.pageview('/how-to-listen');

    return (
        <div className="addict-radio how-to-listen">
            <Header/>
            <div className="page-title container-s">
                <h1><span>how</span> to listen</h1>
                <p>ADDICT RADIO is almost everywhere here is the way you can carry with you your favorite stations.</p>
            </div>
            <div className='container-s text-center devices'>
                <h2>Devices</h2>
                <div className='flex-row'>
                    <a href='/' className='app-link'>
                        <i className='icon-apple'></i>
                        <span>iPhone App<br/>coming soon</span>
                    </a>
                    <a href='/' className='app-link'>
                        <i className='icon-android'></i>
                        <span>Android App<br/>coming soon</span>
                    </a>
                    <a href='https://www.amazon.com/EL-Media-Addict-Radio/dp/B073GXJCKR' className='app-link'>
                        <i className='icon-alexa'></i>
                        <span>On Alexa</span>
                    </a>
                    <a href='/' className='app-link'>
                        <i className='icon-google-home'></i>
                        <span>Google Home<br/>coming soon</span>
                    </a>
                </div>
            </div>
            <div className='container-xs text-center devices'>
                <h2>USE YOUR FAVORITE APP</h2>
                <div className='flex-row'>
                    <a href='/' className='app-link'>
                        <i className='icon-winamp'></i>
                    </a>
                    <a href='/' className='app-link'>
                        <i className='icon-vlc'></i>
                    </a>
                    <a href='/' className='app-link'>
                        <i className='icon-itunes'></i>
                    </a>
                </div>
            </div>
            <div className='container-s text-center devices'>
                <div className="ghack1">
                    <div className='flex-row'>
                        <a href='/files/rock.m3u' className='app-link'>
                            <span>Rock</span>
                        </a>  
                        <a href='/files/alternative.m3u' className='app-link'>
                            <span>Alternative</span>
                        </a>
                        <a href='/files/lounge.m3u' className='app-link'>
                            <span>Lounge</span>
                        </a>
                        <a href='/files/star.m3u' className='app-link'>
                            <span>Star</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default HowToListen;