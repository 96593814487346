import React from "react";
import axios from "axios";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageTitle from "./components/PageTitle";
import RadioStations from "./components/RadioStations";
import ReactGA from 'react-ga';

class AddictRadio extends React.Component {

  constructor() {
    super();
    this.state = {
      radioStations: [],
      radioStationsAreLoaded: false
    };
    this.queue = [];
    this.queue.push(this.getMetadata);
    this.queueInterval = null;
  } 

  
  getRadios = () => {
    let radios = [];
    axios.get(`https://cdn.addictradio.net/s/stations.json`)
    .then(result => {
      const favouriteStations = localStorage.getItem('favourite');
      result.data.forEach(item => {
        if (item.id !== 'ldg') {
          item.stationDetails = {};
          item.stationDetails.artist = '';
          item.stationDetails.title = '';
          item.stationDetails.artistImage = '';

          if (favouriteStations && favouriteStations === item.id) {
            item.isPlaying = true;
            item.favourite = true;
          } else {
            item.isPlaying = false;
            item.favourite = false;
          } 
          item.streams.forEach(element => {
            if (element.url.endsWith(".mp3")) {
              item.streams = element.url;
            }

            const canBePushed = radios.find(radio => radio.id === item.id);

            if(!canBePushed) {
              radios.push(item);  
            }
            
          });
        }

      });

      this.setState({
        radioStations: radios,
        radioStationsAreLoaded: true
      });

      this.queueInterval = setInterval(() => {
        let callback = this.queue.shift();
        if (typeof callback === 'function') {
          callback();
        }
      }, 2500);

    }).catch(error => {

      ReactGA.event({
        category: 'Error',
        action: 'The api is not responding'
      });

      this.setState({
        radioStations: [{
          'id': 'error',
          'name': 'Error',
          'stationDetails': {
            'artist': 'Nobody',
            'title': 'WE LOST THE TRACK',
          },
          'theme': '#46A1E8'
      }],
        radioStationsAreLoaded: false
      });

    });

  }

  componentWillUnmount() {
    if (this.queueInterval) {
      clearInterval(this.queueInterval);
    }
  }

  getMetadata = async () => {
    let stateRadioStations = [...this.state.radioStations];

    try {
      for (let radio of stateRadioStations) {
        if (radio.isPlaying === false) {
          let result = await axios.get(radio.metadataUrl)
          if (result.data.artist !== radio.stationDetails.artist || result.data.title !== radio.stationDetails.title) {
            radio.stationDetails.artist = result.data.artist;
            radio.stationDetails.title = result.data.title;
            const url = "https://api.openstream.co/covers/artist?name=" + encodeURI(result.data.artist);
            let imageUrl = '';
            let imageResult = await axios.get(url);
                imageUrl = "https://covers.openstream.co/" + imageResult.data.data;

              radio.stationDetails.artistImage = imageUrl;
              this.setState({
                radioStations: stateRadioStations
              });
          }
        }
      } 
    } catch (err) {
      //do nothing
    }

    this.queue.push(this.getMetadata);
  }

  initializeReactGA() {
    ReactGA.initialize('UA-3951789-2');
    ReactGA.pageview('/');
  }

  componentDidMount() {
    this.getRadios();
    this.initializeReactGA();
  }
  


  render() {
    return (
      <div className="addict-radio">
        <Header />
        <PageTitle />
        <RadioStations data={this.state.radioStations} stationLoaded={this.state.radioStationsAreLoaded}/>
        <Footer />
      </div>
    );
  }
};

export default AddictRadio;
