import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AddictRadio from './AddictRadio';
import HowToListen from './Page-HowToListen';
import PrivacyPolicy from './Page-PrivacyPolicy';
import NotFoundPage from './Page-NotFound';

class App extends React.Component {

    render() {
        return(
            <Router>
                <Switch>
                    <Route exact path="/">
                        <AddictRadio />
                    </Route>
                    <Route exact path="/how-to-listen">
                        <HowToListen />
                    </Route>
                    <Route exact path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="*">
                        <NotFoundPage/>
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default App