import React, { forwardRef, useEffect } from "react";
import SocialSharing from './SocialSharing';

const RadioStation = forwardRef((props, ref) => {

    let controlStyle = {
      borderColor: '#000',
      background: 'transparent',
    }
    
    const iconBackground = {
      background: props.theme
    };
    const textColor = {
      color: props.theme
    };

    let coverImage = {}
    let artistCoverImage = {};
    let gradientOverlay = {};

    if (props.artistImage) {
      artistCoverImage = {
        backgroundImage: 'url(' + props.artistImage + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }
    }
    
    if (props.play === true) {      
      gradientOverlay = {
        background: 'linear-gradient(-30deg, rgb(255,255,255,0) 0%, ' + props.theme,
      }
      controlStyle = {
        borderColor: 'transparent',
        background: props.theme,
      }

      useEffect(() => {
        props.initSDK(ref);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);



      if (props.cover) {
        coverImage = {
          backgroundImage: 'url(' + props.cover + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      }
    } else {
      if (props.artistImage) {
        gradientOverlay = {
          background:
            "linear-gradient(-30deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%)"
        };
      }
    }
    
    return (
      
      <div className={`radio-station ${props.id} ${props.play ? 'is-playing' : ''}`}>
        { props.play ? <div className="player-effect"></div> : ''}
        <h3>
          <i className="icon-addict-radio" style={iconBackground} />
          {props.name}
        </h3>
        
        <div  className={`cover ${props.artistImage ? '' : 'no-artist-image'}`} style={artistCoverImage}>
          { props.play ? 
          <>
            <h2 dangerouslySetInnerHTML={{__html: props.artist}} />
            <p dangerouslySetInnerHTML={{__html: props.title}} />  
          </> : 
          <div className="no-info-background">
            { props.artist ? <h3 dangerouslySetInnerHTML={{__html: props.artist}}/> : <h3>addict</h3>}
            { props.title ? <span dangerouslySetInnerHTML={{__html: props.title}}/> : <span>{props.name}</span> }
          </div>
          }
          <div className="gradient-overlay" style={gradientOverlay}/>
          { props.play ?
          <div className='song-image'>
            <div className="image" style={coverImage}>
              {!props.cover ? 
                <p dangerouslySetInnerHTML={{__html: 'No album cover No album cover No album cover No album cover No album cover No album cover No album cover'}} style={textColor}/> : ''}
            </div>
          </div> : '' }
        </div>
        <div className="player">
          <i className={`${props.isSaved ? 'icon-favourite' : 'icon-favourite-empty'}`} 
            onClick={() => props.saveStation(props.id)}/>
          <i className={`control ${props.play ? 'icon-pause' : 'icon-play'}`}
            onClick={() => props.click(props.id, ref)}
            style={controlStyle}
            ref={ref}/>
            <SocialSharing stationId={props.name}/>
        </div>
        {!props.play ? 
          <span ref={ref} /> : <audio autoPlay id={props.id} src={props.stream} ref={ref}></audio>}
      </div>
    );
});

export default RadioStation;
