import React from "react";
import { Link } from 'react-router-dom';
import logo from './assets/images/logo.svg'
import ReactGA from 'react-ga';
import PageTitle from './components/PageTitle'

const NotFoundPage = () => {

    ReactGA.initialize('UA-3951789-2');
    ReactGA.pageview('/404');

    return (
        <div className="not-found">
            <div className="container">
                <Link to="/" className="logo">
                    <img src={logo} alt="Addict Radio"/>
                </Link>
                <PageTitle/>
                <div className="banner">404</div>
                <Link to="/" className="button"/>
            </div>
        </div>
    )

}

export default NotFoundPage;