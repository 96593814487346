import React from "react";
import RadioStation from "./RadioStation";
import StationError from "./StationError";
import axios from "axios";
import ReactGA from 'react-ga';

class RadioStations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioStations: []
    };
  }

  changeMetadataFunction = metadata => {

    const properties = metadata.replace(/["']/g, "").split(",");
    const details = {};
    properties.forEach(function (property) {
      const detail = property.split("=");
      details[detail[0]] = detail[1];
    });

    if (details.title) {
      details.title = details.title.replace(details.artist + " - ", "");
    }

    let stateRadioStations = [...this.state.radioStations];
    let cover = "";
    let artistImage = "";
    const coverUrl =
      "https://api.openstream.co/covers?title=" +
      encodeURI(details.artist + " - " + details.title);

    const artistUrl =
      "https://api.openstream.co/covers/artist?name=" +
      encodeURI(details.artist);

    stateRadioStations.forEach(item => {
      if (item.isPlaying === true) {

        if (details.artist) {
          item.stationDetails.artist = details.artist;
        } else {
          item.stationDetails.artist = '';
        }
        if (details.title) {
          item.stationDetails.title = details.title;
        } else {
          item.stationDetails.title = '';
        }

        this.setState({
          radioStations: stateRadioStations
        });
      }
    });

    axios.get(coverUrl)
      .then(result => {
        // eslint-disable-next-line no-useless-escape
        if (result.data.data && result.data.data !== "un\/undefined----.jpg") {
          cover = "https://covers.openstream.co/" + result.data.data;
        } else {
          cover = '';
        }
        stateRadioStations.forEach(item => {
          if (item.isPlaying === true) {
            item.stationDetails.cover = cover;
            this.setState({
              radioStations: stateRadioStations
            });
          }
        });
      })
      .catch(error => {
        stateRadioStations.forEach(item => {
          if (item.isPlaying === true) {
            item.stationDetails.cover = '';
            this.setState({
              radioStations: stateRadioStations
            });
          }
        });
      });

    axios.get(artistUrl)
      .then(result => {
        if (result.data.data) {
          artistImage = "https://covers.openstream.co/" + result.data.data;
        }
        stateRadioStations.forEach(item => {
          if (item.isPlaying === true) {
            item.stationDetails.artistImage = artistImage;
            this.setState({
              radioStations: stateRadioStations
            });
          }
        });
      })
      .catch(error => {
        stateRadioStations.forEach(item => {
          if (item.isPlaying === true) {
            item.stationDetails.artistImage = '';
            this.setState({
              radioStations: stateRadioStations
            });
          }
        });
      });

  };

  initAdswizzSDK = currentPlayerId => {
    window.onload = () => {
      var context = new AudioContext();
      context.resume().then(() => {
        const playPromise = currentPlayerId.current.play();
        playPromise.catch(e => {
          console.log(e);

          let stateRadioStations = [...this.state.radioStations];
          stateRadioStations.forEach((item) => {
            if (currentPlayerId.current.id === item.id) {
              item.isPlaying = false;
            }
          });

          this.setState({
            radioStations: stateRadioStations
          });

        })
      });
    }
    const adswizzSDK = window.adswizzSDK;
    if (adswizzSDK) {
      adswizzSDK.init({
        contentPlayer: currentPlayerId.current,
        playerId: "AddictWebPlayer2019",
        metadata: {
          listeners: [this.changeMetadataFunction],
          connectionType: window.adswizzSDK.MetadataConnection.LONG_POLLING
        }
      });

      adswizzSDK.addMetadataChangedListener(this.changeMetadataFunction);
    }
  };

  playHandler = (stationId) => {
    let stateRadioStations = [...this.state.radioStations];
    stateRadioStations.forEach((item) => {
      if (stationId === item.id) {
        if (item.isPlaying === true) {
          item.isPlaying = false;
          ReactGA.event({
            category: 'Click',
            action: `Pause - ${stationId}`
          });
        } else {
          item.isPlaying = true;
          ReactGA.event({
            category: 'Click',
            action: `Play - ${stationId}`
          });
        }
      } else {
        item.isPlaying = false;
      }
    });
    this.setState({
      radioStations: stateRadioStations
    });

  };

  saveStation = (stationId) => {

    const savedStation = localStorage.getItem("favourite");

    if (stationId === savedStation) {
      localStorage.setItem("favourite", '');
      ReactGA.event({
        category: 'Click',
        action: `Remove Favourite - ${stationId}`
      });
    } else {
      localStorage.setItem("favourite", stationId);
      ReactGA.event({
        category: 'Click',
        action: `Save Favourite - ${stationId}`
      });
    }

    let stateRadioStations = [...this.state.radioStations];

    stateRadioStations.forEach((item) => {
      if (stationId === item.id) {
        if (item.favourite === true) {
          item.favourite = false;
        } else {
          item.favourite = true;
        }
      } else {
        item.favourite = false;
      }
    });

    this.setState({
      radioStations: stateRadioStations
    });

  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.data !== prevState.data) {
      return ({ radioStations: nextProps.data })
    }

  }

  createJSX = () => {
    let RadioStationJSX = "";
    const radioStations = [...this.state.radioStations];
    RadioStationJSX = radioStations.map(item => {
      const ref = React.createRef();
      let title,
        artist,
        cover,
        artistImage = "";
      if (item.stationDetails) {
        title = item.stationDetails.title;
        artist = item.stationDetails.artist;
        cover = item.stationDetails.cover;
        artistImage = item.stationDetails.artistImage;
      }
      if (this.props.stationLoaded) {
        return (
          <RadioStation
            name={item.name}
            id={item.id}
            theme={item.theme}
            stream={item.streams}
            key={item.id}
            play={item.isPlaying}
            click={this.playHandler}
            ref={ref}
            title={title}
            artist={artist}
            cover={cover}
            initSDK={this.initAdswizzSDK}
            artistImage={artistImage}
            saveStation={this.saveStation}
            isSaved={item.favourite}
            getData={this.getMetadata}
          />
        );
      } else {
        return (
          <StationError
            name={item.name}
            id={item.id}
            theme={item.theme}
            key={item.id}
            title={title}
            artist={artist}
          />
        );
      }

    });

    return <div className="radio-stations">{RadioStationJSX}</div>;
  };

  render() {
    return this.createJSX();
  }
}

export default RadioStations;
