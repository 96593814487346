import React from "react";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <Link to="/" className="logo">
        <img src={logo} alt="Addict Radio" />
      </Link>
      <div>
        <Link to='/how-to-listen' className="link"><i className="icon-headset"></i> How to listen</Link>
        <a href="mailto:contact@addictradio.net" className="link"><i className="icon-mail"></i>Get in touch</a>      
      </div>
    </div>
  );
};

export default Header;
